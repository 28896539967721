// en.js
export default {
  home: {
    tab1: "Example",
    tab2: "Pricing",
    tab3: "FAQ",
    lang: "中",
    title1: "Empower your future with ",
    title2: "MIYA GPT ",
    title3: "Al solutions for a smarter tomorrow",
    btn1: "Start the free experience now",
    lRights1: "MIYAIP",
    lRights2: "STMD INC © 2023 All Rights Reserved ",
    lRights3: "苏ICP备09070171号-5",
    product1: "AI Chat",
    productText1:
      "Enjoy seamless conversations, and MIYA GPT takes you to a new level of artificial intelligence chat.",
    product2: "AI Painting",
    productText2:
      "Unleash your potential, MIYA GPT uses your wild imagination as a boat and your amazing creativity as a pulp, touching the edge of your dreams with you.",
    title5: "Product Pricing",
    titleText5:
      "Different pricing meets your different needs and brings you a more free experience. (1RMB=1Mi value, different recharge limits enjoy different discounts)",
    itemText1: "[ Default (GPT-3.5) ]",
    itemText2: "[ GPT-4 ]",
    itemText3: "[ Midjourney ]",
    unit: "M value",
    label: "order",
    t1: "Screenwriting",
    t2: "Written Answer",
    t3: "Ultra fast response speed",
    t4: "Answers are more accurate and closer to the truth",
    t5: "More professional creativity",
    t6: "Intelligent drawing",
    t7: "Fast drawing speed",
    t8: "Super Strong Composition and Color Aesthetics",
    t9: "Professional and rich stylization skills",
    title6: "FAQ",
    titleText6: "To answer some common questions for you.",
    lTel: "tel",
    lEmail: "support email",
    lTime: "support online time",
    lAddress: "office address",
    lPolicy: "Service Policy",
    home: "home",
    goLogin: "Log in/Register",
    goChatDraw: "Chat/Painting",
    fqa1: "What is MIYA GPT?",
    fqa11:
      "MIYA GPT is an AI chat system built on the latest version of ChatGPT.",
    fqa2: "Is my data secure?",
    fqa21:
      "We highly respect your privacy. We guarantee that we will not collect or store any personal information or behavioral data about you. Our products or services do not track your browsing data, whether using our website or our applications. We will not record your browsing history, search history, click history, and other information. We will not save your IP address or device information. Our website or application does not require you to provide any personal information for registration or use. If you have any doubts or questions, please feel free to contact us at any time. We will do our best to protect your privacy and ensure that our products and services comply with the strictest privacy standards.",
  },
  operation: {
    title1: "operation",
    title2: "AI model",
    type1: "AI Chat",
    type2: "AI Painting",
    qq1: "QQ group",
    qq2: "QQ Scan Code Follow",
    qq3: "QQ Channel",
    order: "Recharge Record",
    warn1:
      "Overloading historical data may affect usage, please save data cleaning records in a timely manner!",
  },
  login: {
    close1: "Click this area to close",
    title1: "Login",
    title2: "Register",
    title3: "Reset Password",
    username: "Mobile phone number/email",
    password: "Password",
    forgetPassword: "Forget Password",
    login: "Log in",
    checkedText: "By registering, you agree to the ",
    checkedText1: "service agreement ",
    checkedText2: "and ",
    checkedText3: "Privacy Agreement",
    checkedText4: "Registration represents consent",
    signup: "Sign Up",
    phone: "Phone number",
    phoneCode: "Code",
    sendCode: "Obtain code",
    codeTip:
      "If you have not received the verification code, please check the SMS trash can",
    repeatPassword: "Repeat Password",
    placeholder4: "Please enter the invitation code (can be filled in blank)",
    errorPassword: "Please enter the password",
    repeatPassword1: "The two passwords entered do not match!",
    error1: "Please read and agree to the service terms",
    error2: "Account or password error, login failed!",
    errorEmail: "Please enter the correct account",
    errorPhoneCode: "Please enter the correct phone verification code",
    errorPhone: "Please enter your phone number",
    errorCaptcha: "Please enter the verification code",
    placeholder3: "Please enter ",
    btnOK: "Confirm",
    editPassword: "Change password",
    balance: "Balance",
    logout: "Log out",
    success: "Operation successful",
    sendSuccess: "Successfully sent",
    errorLogin: "Please log in to the system first",
    commit: "Submit",
    agree: "agree",
    cancel: "Cancel",
    backHome: "Back Homepage",
    c1: "CN",
    c2: "US",
    c3: "HK",
    c4: "MO ",
    c5: "TW",
    c6: "JP",
    c7: "KR",
    c8: "SG",
    c9: "MY",
    c10: "PH",
    c11: "TH",
    c12: "IN",
    c13: "UK",
    c14: "DE",
    c15: "CA",
    c16: "FR",
    c17: "IT",
    c18: "AU",
    c19: "NZ",
    c20: "TZ",
    c21: "GH",
    c22: "KE",
    c23: "SE",
    c24: "EG",
    c25: "PE",
    c26: "BO",
    c27: "CO",
    c28: "EC",
    c29: "MX",
    c30: "VN",
    c31: "FI",
    c32: "NL",
  },
  ai: {
    modelFree: "The following models are available for free...",
    tip1: "Join Context",
    editName: "Modify Name",
    copy: "copy",
    download: "download",
    split: "Image segmentation",
    modelTip1: "Fee deduction rules: ",
    modelTip2: " AI chat ",
    modelTip3: " [Default (GPT-3.5)、Claude-100K、Bard] ",
    modelTip4: " deducts ",
    modelTip5: " M for each question, AI chat ",
    modelTip6: " [GPT-4] ",
    modelTip7: " deducts ",
    modelTip8: " M for each question, and ",
    modelTip9: "AI painting ",
    modelTip10: " [Midjourney] ",
    modelTip11: "deducts a ",
    modelTip12:
      " M value for each painting, M value will not be deducted if unsuccessful.",
    modelTip13: " [Claude-100K、Bard] ",
    modelTip14: "is free.",
    t1: "Chat",
    t2: "Painting",
    config: "Prompt settings",
    tip2: "Prohibit the generation of illegal and irregular images. Once discovered, the account will be permanently banned! Drawing keywords in Chinese will fail to generate. It is recommended to use GPT to translate them into English first!",
    fx: "Keyword universal formula",
    promptPlaceholder:
      "Enter the conversation style you want here, for example: I need to speak to me in a more official tone",
    fxTitle: "Keyword universal formula",
    fx1: "Screen subject: ",
    fx2: "Element background: ",
    fx3: "Composition lens: ",
    fx4: "Style reference: ",
    fx5: "Image settings: ",
    fx6: "Example: ",
    fx11: "Tell AI what you want to draw? The protagonist of the scene, such as a boy playing basketball.",
    fx21: "Set up an environment, location, surrounding objects, and where the background is.",
    fx31: "For example, emphasizing depth of field, object position, full body, half body, center alignment, three views, depth of field.",
    fx41: "Artistic style, the name of the artist, the name of the work, such as Bauhaus, Hayao Miyazaki, etc.",
    fx51: "The suffix words for Midjournal, such as ar/seed/iw/, can control the screen, and can also add: lighting/color/atmosphere/emotion/artistic form.",
    fx61: "AFute Chinese girl with brown eyes and long lighting Hayao Miyazaki style",
    fxTip:
      "Translate GPT into English for more accurate generation, pay attention to the spaces in the middle of the keywords. If the generation fails, please adjust the keywords.",
    balance: "balance",
    preferential: "preferential",
    discount: "discount",
    symbol: "$",
    customAmountlabel: "VIP Exclusive Package",
    customAmountplaceholder: "Custom Amount $1~$10000 1:1.6[M value]",
    payNow: "BUY NOW",
    payType: "Payment method",
    payTip: "Please scan the code for payment",
    payBtn: "Payment completed",
    Alipay: "Alipay",
    WeChat: "WeChat",
    commingSoon: "Contact customer service to activate",
    errorPayType: "Please select payment method",
    inputPlaceholder:
      "Please create or select a new session first, and then enter your question here",
    stop: "Stop answering",
    stopDraw: "Stop painting",
    newChat: "New Chat",
    newDraw: "New Painting",
    new: "New",
    new1: "",
    new2: "records in total",
    my: "My",
    wordCount: "Word count: ",
    send: "send",
    drawLoading: "AI painting in progress, please wait",
    defaultChat:
      "Hello, I am an AI language model. I can provide some commonly used services and information, such as:<br/>1 Translation: I can translate Chinese into English, English into Chinese, and other languages such as French, Japanese, Spanish, etc <br/> 2. Consulting services: If you have any questions that require consultation, such as health, legal, investment, etc., I can do my best to assist you <br/> 3. Small talk: If you feel lonely or bored, we can talk about some interesting topics to reduce your stress< Please let me know where you need help, and I will provide you with corresponding information and suggestions based on your needs.",
    defaultDraw:
      "There are currently no works available, please input keywords to start painting",
    emptyChat: "There is currently no chat content",
    emptyDraw: "There is currently no painting content",
    copy1: "Replicating Success",
    copy2: "copy failed",
    error3: "An error occurred, please re-enter",
    error4:
      "The current usage has exceeded 10 times. Please enter the following code on the QQ channel and use it for free",
    drawInputPlaceholder:
      "Please create or select a new historical painting first, and then enter the question here. It is recommended to use English input",
    bandPhone: "Bind phone number",
    userUpload: "Click to upload images to blend",
    noFileUploaded: "No file uploaded",
  },
  order: {
    options: "Option",
    title1: "Order Record",
    all: "All ",
    noPay: "Unpaid ",
    pay: "Paid ",
    placeholderSearch1: "Please enter search",
    orderId: "Order ID",
    consumptionId: "Consumer ID",
    orderName: "Package Name",
    price: "amount ",
    mi: "M value",
    orderStatus: "Status",
    createTime: "order time",
    payTime: "time of payment",
    noData: "No Data",
    btnMi: "Recharge",
    rmb: "RMB",
    deleteDialogTitle: "Confirm deletion?",
    deleteDialogText: "Unable to recover after deletion",
    title2: "Consumption records",
    useWay: "Consumption type",
    usePrice: "Consumption amount",
    useTime: "consume time",
  },
  invite: {
    inviteList: "Invitation Record",
    t2: "Invite a new person to reward 2 M points",
    inviteCode: "Invitation code",
    text1: "",
    text2: " new people have been invited and ",
    text3: " M value has been awarded",
    table1: "ID",
    table2: "Invited ID",
    table3: "Reward M value",
    table4: "Obtaining time",
    text4: "Invite a new person to reward ",
    text5: " M points",
  },
  error: {
    e1: "Midjournal images have been sent up to the daily limit of 10 times. Please try again the next day",
    e2: "Please bind your phone number first",
    e3: "Insufficient M value, please recharge",
    e4: "The image is currently loading. Please proceed later",
  },
  tos: {
    t1: "MIYAGPT Service Agreement",
    t2: "MIYAGPT Platform Privacy Agreement",
    t3: "MIYAGPT Legal Compliance Guidelines",
    t4: "MIYAGPT Refund Policy",
    c11: "Party A: Platform User",
    c12: "Party B: STMD INC",
    t1_1: "1、 Purpose of the agreement",
    c1_11:
      "This agreement aims to clarify the rights and obligations of Party A and Party B during the use of MIYAGPT platform services, and to safeguard the legitimate rights and interests of both parties.",
    t1_2: "2、 Service content",
    c1_21:
      "The second party provides intelligent chat assistant services to the first party through the MIYAGPT platform to meet the needs of the first party in problem-solving, consultation and communication.",
    c1_22:
      "The second party will respond and interact based on the information and needs provided by the first party, as well as the existing knowledge and skills.",
    c1_23:
      "The second party will make every effort to provide accurate and timely information and suggestions, but shall not be responsible for any consequences caused by incorrect or incomplete information.",
    t1_3: "3、 User rights",
    c1_31:
      "The first party has the right to raise questions and requirements during the use of MIYAGPT platform services, and to receive answers and suggestions provided by the second party.",
    c1_32:
      "The personal information of Party A will be strictly protected, and Party B will not provide it to any third party unless expressly authorized by Party A or required by law.",
    c1_33:
      "The first party has the right to terminate the use of MIYAGPT platform services at any time and request the deletion of their personal information. The second party will do its best to meet the request of the first party.",
    t1_4: "4、 User Obligations",
    c1_41:
      "When using the MIYAGPT platform services, Party A shall comply with national laws and regulations and shall not engage in any illegal or irregular activities using the platform.",
    c1_42:
      "The first party shall ensure that the information provided to the second party is accurate and complete, and shall be responsible for any consequences arising from it.",
    c1_43:
      "Party A shall properly keep its account and password on the MIYAGPT platform and shall not provide it to others for use.",
    t1_5: "5、 Limitation of Liability",
    c1_51:
      "The second party shall not be liable for any losses or risks incurred by the first party in using the MIYAGPT platform services, including but not limited to property damage, data loss, etc.",
    c1_52:
      "The second party shall not be responsible for any service interruption or malfunction caused by force majeure, computer viruses, hacker attacks, or other reasons.",
    c1_53:
      "The second party does not guarantee the continuity, security, and accuracy of MIYAGPT platform services, and shall not be responsible for any consequences arising therefrom.",
    t1_6: "6、 Intellectual Property",
    c1_61:
      "The second party owns the intellectual property and technology related to the MIYAGPT platform. Without the explicit authorization of the second party, the first party shall not copy, modify, disseminate or use it in any other way.",
    c1_62:
      "The Party A has the right to organize and analyze relevant information such as issues and suggestions provided during the use of MIYAGPT platform services, and further utilize it while protecting user privacy.",
    t1_7: "7、 Agreement Change and Termination",
    c1_71: `The provisions of this agreement may be adjusted at any time according to changes in laws, regulations, and Party B's policies. The adjusted agreement will be publicly announced and take effect on the MIYAGPT platform.`,
    c1_72:
      "If Party A violates the provisions of this agreement, Party B has the right to terminate the provision of MIYAGPT platform services to Party A at any time.",
    t1_8: "8、 Dispute resolution",
    c1_81: `The interpretation and execution of this agreement shall be governed by the laws of United State. Any disputes arising between the parties during the performance of this agreement shall be resolved through friendly consultation; If negotiation fails, either party may file a lawsuit with the people's court where Party B is located.`,
    t1_9: "[ Important reminder ]",
    c1_91:
      "Please do not involve national security, personal sensitive information, or other content during the use of MIYAGPT platform services to avoid causing losses to yourself and others.",
    t2_1: "Privacy Policy",
    c2_11:
      "We attach great importance to your privacy and are committed to protecting the security of your personal information. In order to better serve you, we provide the following privacy policy instructions. We hope you can feel more confident when using our services.",
    t2_2: "scope of application",
    c2_21:
      "This privacy policy applies to our MIYAGPT platform and related services, including but not limited to chat robots, speech recognition, speech synthesis, intelligent question answering, and other functions.",
    t2_3: "Information collection and use",
    c2_31:
      "We will collect information that you proactively provide or collect through technical means when using our services, such as:",
    c2_32:
      "1. Personal information: including but not limited to name, gender, date of birth, occupation, contact information, etc.",
    c2_33:
      "2. Device information: including but not limited to device model, operating system version, unique device identifier, etc.",
    c2_34:
      "3. Log information: When you use our service, we will automatically collect your access logs, including but not limited to IP address, access time, browsing records, etc.",
    c2_35:
      "We will use the above information to provide services, improve service quality, ensure service safety, and conduct market research and user analysis.",
    t2_4: "Information sharing and disclosure",
    c2_41:
      "We promise not to sell, rent or share your personal information with third parties, unless authorized by you or required by laws and regulations.",
    c2_42:
      "We will disclose your personal information to the public in accordance with laws and regulations, including but not limited to the following situations:",
    c2_43: "1. With your prior explicit consent or authorization;",
    c2_44:
      "2. Disclosure to relevant institutions in accordance with laws and regulations;",
    c2_45:
      "3. To disclose in order to safeguard our legitimate rights and interests;",
    c2_46:
      "4. Protect the safety of other users or the public in emergency situations.",
    t2_5: "Information Storage and Protection",
    c2_51:
      "We promise to adopt reasonable security measures to protect the security of your personal information. We will use encryption technology, isolation technology, and other methods to ensure the security and confidentiality of your personal information.",
    c2_52:
      "But please note that the internet is not an absolutely secure environment, and we cannot guarantee absolute security. If unfortunate events such as data leakage occur, we will promptly initiate emergency plans and take reasonable and effective measures to prevent the event from expanding.",
    t2_6: "Cancellation and modification",
    c2_61:
      "You can apply to stop using our services at any time. At the same time, you can also apply to modify, update, or delete your personal information. To protect your privacy and security, we need to verify your identity before processing your request.",
    t2_7: "The scope, content, and updates of this policy",
    c2_71:
      "This policy applies to the MIYAGPT system, including but not limited to chat robots, speech recognition, speech synthesis, intelligent question answering and other functions. The content of this policy includes but is not limited to information collection and use, information sharing and disclosure, information storage and protection, cancellation and modification, etc. We may update this policy based on changes in services, legal regulations, or other conditions, and the updated policy will be published on the MIYAGPT system.",
    c2_72:
      "If you have any questions or suggestions, please contact us through the feedback channel within the MIYAGPT platform.",

    t3_1: "Legal Compliance Guidelines for Using MIYAGPT",
    t3_2: "Intellectual Property Laws: Users of MIYAGPT must ensure compliance with U.S. intellectual property laws, including copyrights, trademarks, and patents. Respect the rights of content creators and obtain appropriate permissions before using or reproducing copyrighted material.",
    t3_3: "Data Protection Regulations: Adhere to U.S. data protection laws, such as the California Consumer Privacy Act (CCPA) and the Health Insurance Portability and Accountability Act (HIPAA), when handling personal data. Obtain consent for data processing and implement security measures to safeguard sensitive information.",
    t3_4: "Federal Trade Commission (FTC) Guidelines: Comply with FTC guidelines regarding truthfulness, fairness, and transparency in advertising and marketing practices. Disclose any sponsored content or endorsements generated using MIYAGPT to ensure transparency for consumers.",
    t3_5: "Securities Laws: Be mindful of U.S. securities laws administered by the Securities and Exchange Commission (SEC) when generating content related to financial markets, investments, or securities. Avoid making false or misleading statements that could manipulate stock prices or mislead investors.",
    t3_6: "Anti-Discrimination Laws: Avoid generating content that discriminates against individuals or groups based on protected characteristics such as race, gender, religion, disability, or age. Ensure compliance with federal anti-discrimination laws, including the Civil Rights Act of 1964 and the Americans with Disabilities Act (ADA).",
    t3_7: "Consumer Protection Laws: Comply with U.S. consumer protection laws, including the Consumer Protection Act and state-specific regulations, to ensure fairness, honesty, and transparency in business practices. Avoid deceptive or unfair trade practices when interacting with consumers.",
    t3_8: "Children's Online Privacy Protection Act (COPPA): If generating content targeted at children under the age of 13, ensure compliance with COPPA regulations, which require obtaining parental consent before collecting personal information from children.",
    t3_9: "Telecommunications Laws: Adhere to regulations enforced by the Federal Communications Commission (FCC) governing telecommunications services, including restrictions on spam messaging, robocalls, and telemarketing practices.",
    t3_10:
      "Accessibility Standards: Ensure that digital content generated by MIYAGPT complies with accessibility standards outlined in the Americans with Disabilities Act (ADA) to provide equal access to individuals with disabilities.",
    t3_11:
      "Legal Liability: Users are responsible for the content generated using MIYAGPT and may be held liable for any unlawful or harmful materials produced. Exercise caution and conduct due diligence to avoid legal repercussions.",
    t3_12:
      "By adhering to these legal guidelines and regulations, users can ensure compliance with U.S. laws and regulations while using MIYAGPT for various purposes.",
    t4_1: "At MiyaGPT,we are committed to providing AI conversation service and customer satisfaction. We understand that there may be instances where customers are not completely satisfied and for this reason, we have put forth the following refund policy:",
    t4_2: "No Refunds: As a general rule, due to the digital nature of our products and services, all sales made on MiyaGPT are final and non-refundable. This means, after your purchase has been made and services have been delivered, we do not typically offer a refund.",
    t4_3: "Exceptional Circumstances: Notwithstanding the above, we do acknowledge that exceptions may occur. In the event that you experience any substantial technical issues with our service that are directly attributable to MiyaGPT, and which we are unable to resolve after being given a reasonable opportunity to do so, a refund may be issued.",
    t4_4: "Procedure: In order to request a refund under such exceptional circumstances, please contact our customer service team directly with detailed information about the issues you're experiencing. We'll undertake an assessment and determine whether a refund is applicable under this policy.",
    t4_5: "Jurisdiction: This Refund Policy shall be governed by and interpreted in accordance with the laws of the United States. Any disputes arising out of or related to the Refund Policy will be heard in the courts located within the United States.",
    t4_6: "Policy Changes: MiyaGPT reserves the right to modify this Refund Policy at any time in accordance with this provision. If we make changes to this Refund Policy, we will post the revised policy on our website.",
    t4_7: "We highly recommend contacting our customer support team for assistance if you experience any issues regarding our services. We're here to help you every step of the way.",
    t4_8: "By purchasing our services, you signify your acceptance of this Refund Policy. If you do not agree to this policy, please do not use MiyaGPT services. Your continued use of the MiyaGPT website and services following the posting of changes to this policy will be deemed your acceptance of those changes.",
  },
};
